<template>
    <div class="ku-main">
        <SOURCE_MATERIA :material="this.$route.params.Material" />
    </div>
</template>
  
<script>
import SOURCE_MATERIA from "@/components/work/sourceMaterial.vue";
export default {
    components: {
        SOURCE_MATERIA,
    },
    created() {
        // let material = this.$route.params.Material;
        console.log(this.$route.params.Material);
        // console.log(material);
        /**
        switch (material) {
            case "1":
                this.kuType = type;
                break;

            case "2":
                this.kuType = type;
                break;
            case "3":
                this.kuType = type;
                break;

            default:
                // 错误跳转
                // console.log(404);
                // this.$router.replace("/404");
                this.kuType = 2;
                break;
        }
        // this.kuType = this.$route.query.type;
        // 获取根目录素材
        * */
    },
    data() {
        return {
            kuType: "", // 1创意库，2素材库，3导入
        };
    },
    methods: {},
};
</script>
  
<style lang="less" scoped>
.ku-main {
    height: 100%;
}
</style>
  